<template>
    <div>
      <div v-if="type === 'RACE_RESULTS'" class="mb-4">
        Import mode:<br/>
        <v-btn-toggle v-model="mode" mandatory class="multiline-btns">
          <v-btn value="FRESH" small>
            Fresh import<br/>(replaces all current data)
          </v-btn>
          <v-btn value="MERGE" small>
            Updates only<br/>(merge with current data)
          </v-btn>
        </v-btn-toggle>
      </div>

      <div v-if="type === 'RACE_RESULTS' && event">
        <DateWithTimeZonePicker  v-model="timestamp" :timeZone="event.timeZoneOlson" :minDate="event.from" :maxDate="event.till" label="In-person race date" class="pt-0 mt-0"/>
      </div>
      <v-file-input label="Select a .xlsx file" v-model="file" v-on:change="fileSelected"></v-file-input>
      <v-alert v-if="syncResult && syncResult.status == 'OK'" type="success"><strong>All good! Registration data is saved.</strong></v-alert>
      <v-alert v-else-if="syncResult && syncResult.status == 'INFO'" type="info"><strong>{{ syncResult.msg }}</strong></v-alert>
      <v-alert v-else-if="syncResult && syncResult.status == 'ERROR'" type="error">Unfortunately, we couldn't process your file: <strong>{{ syncResult.msg }}</strong></v-alert>
      <v-btn color="primary" outlined @click="uploadFile" :loading="$store.getters.isLoading">Upload file</v-btn>
      <span v-if="type !== 'RACE_RESULTS'" class="ml-4 text-muted">Please select a full import file, uploading a new file will <strong>replace</strong> all existing data.</span>
    </div>
</template>

<script>
import { DateTime } from 'luxon'
import eventManagerService from "@/services/eventManagerService";
import DateWithTimeZonePicker from "@/components/DateWithTimeZonePicker";

export default {
  name: "ExcelUploadForm",
  components: {
    DateWithTimeZonePicker,
  },
  props: {
    event: Object,
    eventId: String,
    raceId: String,
    type: String,
  },
  data: function() {
    return {
      file: null,
      mode: 'FULL',
      timestamp: null,
      syncResult: null,
    };
  },
  created() {
  },
  mounted() {
    if (this.type === 'RACE_RESULTS' && this.event && DateTime.fromISO(this.event.from).startOf("day").ts == DateTime.fromISO(this.event.till).startOf("day").ts) {
      // preselect the day if it's a one-day event
      this.timestamp = this.event.from;
    }
  },
  methods: {
    async fileSelected() {
      this.syncResult = null;
    },

    async uploadFile() {
      this.syncResult = null;
      let formData = new FormData()
      if (this.file) {
        formData.append("file", this.file, this.file.name);
        formData.append("eventId", this.eventId);
        formData.append("raceId", this.raceId);
        formData.append("mode", this.mode);
        if (this.timestamp) {
          formData.append("timestamp", this.timestamp);
        }
        if (this.type == 'PRELOADED_ACTIVITIES') {
          this.syncResult = (await eventManagerService.uploadPreloadedActivities(this.eventId, formData)).data;
        }
        else if (this.type == 'TEAM_ASSIGNMENT') {
          this.syncResult = (await eventManagerService.uploadTeamAssignment(this.eventId, formData)).data;
        }
        else if (this.type == 'RACE_RESULTS') {
          this.syncResult = (await eventManagerService.uploadRaceResults(this.eventId, this.raceId, formData)).data;
        }
        else if (this.type == 'IMPORT_ACTIVITIES') {
          this.syncResult = (await eventManagerService.import(this.eventId, 'activities', formData)).data;
        }

        if (this.eventId && this.syncResult.status == 'ONGOING' && this.syncResult.id){
          this.$router.replace({ name: 'eventmanagerTask', params: {id: this.eventId, taskId: this.syncResult.id}});
        }
        else if (this.syncResult.status == 'OK'){
          this.$helpers.toastResponse(this, this.syncResult, 'Data uploaded successfully.');
          this.$emit('uploaded'); 
        }
      }
      else {
        this.syncResult = {
          status: "ERROR",
          msg: "Please select a file to upload."
        };
      }
    },
  },
  computed: {
  },
};
</script>

<style scoped>
</style>