<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <v-toolbar elevation="0" color="grey lighten-5">
        <v-toolbar-title class="ml-3 py-2 subtitle">
          Team Assignment
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="actions">
          <v-btn v-if="event.join_select_team || event.join_random_team_assignment" class="mr-4" @click="createTeam">Add Team</v-btn>
          <v-btn class="mr-4" @click="showTeamAssignmentDialog=true">Upload</v-btn>
        </div>
      </v-toolbar>
      <v-divider/>

      <div >
        <v-alert v-if="!event.published" type="info" tile>This event is not yet published. An event must be published before it can be used or viewed.</v-alert>
        <v-alert v-if="event.team_max_members" type="info" tile>This event has a max team size configured of {{event.team_max_members}}. Note that this maximum may get exceeded during assignment.</v-alert>
        <v-alert v-if="event.join_random_team_assignment" type="info" tile>This event has random team assignment enabled. New participants are automatically, randomly, and evenly assigned to a team.</v-alert>
        <v-alert v-if="teamAssignmentStatus && teamAssignmentStatus.status == 'INFO'" type="info" tile>{{ teamAssignmentStatus.msg }}</v-alert>
      </div>      

      <div v-if="teamAssignment && teamAssignment.assignment && teamAssignment.assignment.teams.length > 0">
        <v-alert type="info" tile>
          <p>There are currently <strong>{{teamAssignment.user_count}}</strong> joined users of which  <strong>{{teamAssignment.assigned}}</strong> have joined a team.</p>
          <p><strong>{{teamAssignment.unassigned}}</strong> users will be randomly assigned to a team (to make teams of an average size of {{ teamAssignment.avg_team_size }}).</p>
        </v-alert>
        <v-card-title class="subtitle pt-4">
          {{teamAssignment.assignment.teams.length}} joined teams
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="fa-search"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-card-text>
          By default users are assigned across all teams. You can restrict this by selecting one or more teams and then tapping the Recalculate button at the bottom to restrict assignment to only selected groups.
        </v-card-text>
        <v-card-text>
          <strong>Merge Teams:</strong> First select the team you want to keep using, then one or more other teams you want to merge into the first selected team and tap Merge to apply.
        </v-card-text>
        <v-data-table 
          :headers="groupHeaders"
          :items="teamAssignment.assignment.teams"
          :items-per-page="50"
          :search="search"
          >
          <template v-slot:item.included_group_ids="{ item }">
            <v-checkbox v-model="includedGroupIds" multiple hide-details dense :value="item.id"/>
          </template>
          <template v-slot:item.count_after="{ item }">
            <span :class="{ 'yellow': item.count_after > item.count_before }">{{item.count_after}}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small v-if="item.count_after > event.team_max_members" color="warning" title="This team will exceed maximum team size">fadl fa fa-exclamation-triangle</v-icon>
            <v-btn text color="primary" :to="{name: 'profileGroupsEvent', params: {id: item.id, eventId: event.id}}">
              Details
            </v-btn>
            <v-icon v-if="eventUtil.hasRole('FULL_ACCESS')" small title="Rename" color="primary" class="mr-4" @click="renameTeam(item)">
              fa-edit 
            </v-icon>
            <v-icon v-if="item.count_before == 0 && eventUtil.hasRole('FULL_ACCESS')" small title="Delete" color="error" class="mr-4" @click="deleteTeam(item)">
              fa-trash
            </v-icon>
          </template>
        </v-data-table>
      </div>
      <v-card-text v-else-if="teamAssignment">
        No groups or teams have been created yet for this event. You can manage teams by importing registration data with team information assigned to it. Please read our Knowledge Base for more information on how to setup team races.
      </v-card-text>

      <v-card-text >
        <v-btn :disabled="includedGroupIds.length < 2" color="primary" large outlined class="mr-4" @click="mergeTeams" >Merge</v-btn>
        <v-btn v-if="teamAssignment && teamAssignment.unassigned && teamAssignment.assignment && includedGroupIds.length" color="primary" large outlined class="mr-4" @click="recalculate" >Recalculate</v-btn>
        <v-btn v-if="teamAssignment && teamAssignment.unassigned && teamAssignment.assignment" color="primary" @click="save" large>Randomly Assign Users Now</v-btn>
      </v-card-text>
    </v-card>

    <v-dialog v-if="event" v-model="showTeamAssignmentDialog" max-width="400px">
      <v-card>
        <v-card-title>Upload Team Assignment file</v-card-title>
        <v-card-text>
          You can upload an Excel file with team assignment. Add two columns: 'Code' and 'Team' to assign users to a specific team.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <ExcelUploadForm :event-id="event.id" @uploaded="showTeamAssignmentDialog=false;getProfile()" type="TEAM_ASSIGNMENT"/>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="showTeamAssignmentDialog = false">{{$t('shared.close')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>            

  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import ExcelUploadForm from "@/components/ExcelUploadForm";
import EventUtil from "@/util/eventUtil";
import Header from './_Header.vue'

export default {
  name: "Participants",
  components: {
    Header,
    ExcelUploadForm,
  },
  props: {
  },
  data() {
    return {
      event: null,
      teamAssignment: null,
      teamAssignmentStatus: null,
      search: null,
      includedGroupIds: [],
      showTeamAssignmentDialog: false,
      groupHeaders: [
        { text: 'Include', align: 'start', sortable: true, value: 'included_group_ids',},
        { text: 'Name', align: 'start', sortable: true, value: 'name',},
        { text: 'Current Members', align: 'start', sortable: true, value: 'count_before',},
        { text: 'Post-assignment Members', align: 'start', sortable: true, value: 'count_after',},
        { text: 'Actions', align: 'start', sortable: false, filterable: false, value: 'actions',},
      ],
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getEvent(id) {
      var response = await eventManagerService.get(id);
      this.event = response.data;
      this.teamAssignment = (await eventManagerService.getTeamAssigment(id, this.includedGroupIds)).data;
      this.teamAssignmentStatus = (await eventManagerService.getTeamAssignmentStatus(id, this.includedGroupIds)).data;
      console.log('data loaded', this.participants, this.teamAssignment, 'request:' , this.teamAssignment.assignment);
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

    async recalculate() {
      await this.getEvent(this.$route.params.id);
    },

    async createTeam() {
      var team = prompt("Please enter the name the team to add:");
      if (team) {
        await eventManagerService.createTeam(this.event.id, team);
        await this.recalculate();
      }
    },

    async renameTeam(item) {
      var newName = prompt(`Please enter the new name for them ${item.name}?`);
      if (newName) {
        var result = await eventManagerService.updateGroupParticipant(this.event.id, item.id, newName);
        this.$helpers.toastResponse(this, result.data, 'Successfully renamed the team.');
        if (result.data.status == "OK") {
          await this.recalculate();
        }
      }
    },

    async mergeTeams() {
      //console.log('includedGroupIds', this.includedGroupIds);
      if (confirm(`Are you sure you want to merge the selected teams? We keep the first selected team.`)) {
        var result = await eventManagerService.mergeTeams(this.event.id, this.includedGroupIds);
        this.$helpers.toastResponse(this, result.data, 'Successfully merged the teams.');
        if (result.data.status == "OK") {
          await this.recalculate();
        }
      }
    },

    async deleteTeam(item) {
      if (confirm(`Are you sure you want to delete the team ${item.name}?`)) {
        var result = await eventManagerService.deleteGroupParticipant(this.event.id, item.id);
        this.$helpers.toastResponse(this, result.data, 'Successfully deleted the team.');
        if (result.data.status == "OK") {
          await this.recalculate();
        }
      }
    },
    async save() {
      if (confirm(`Are you sure you want to apply the proposed team assignment? This cannot be undone.`)) {
        this.teamAssignment = (await eventManagerService.saveTeamAssigment(this.event.id, this.teamAssignment.assignment)).data;
      }
    }
  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Teams', exact: true, to: { name: 'eventmanagerTeams', params: {id: this.event.id}} },
        { text: 'Team assignment', disabled: true },
      ];
    },
    eventUtil() {
      return new EventUtil(this, this.event, this.race);
    },
    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

